import { Button as ButtonType } from 'app/blocks/button'
import React from 'react'
import Section from '../structure/Section'
import { ButtonLink } from '../primitive/Button'
import { useStores } from 'app/store'

interface Props {
  block: ButtonType
}

export const ButtonBlock: React.FC<Props> = ({ block }) => {
  const { userStore } = useStores();
  const { buttonLabel, buttonLink } = block

  const isLoggedIn = userStore?.user

  const href = isLoggedIn ? buttonLink : '/perks'

  return (
    <Section
      gap="$3.5"
      paddedX
      ai="center"
      jc="center"
      maxWidth={800}
      mx="auto"
      $gtXs={{ gap: '$3.5' }}
    >
      <ButtonLink size='$3' href={href} mx="auto">
        {buttonLabel}
      </ButtonLink>
    </Section>
  )
}
