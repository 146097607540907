import { Banner as BannerType } from 'app/blocks/banner'
import React from 'react'
import { AdStandard } from './Ad/AdStandard'
import { AdFullWidth } from './Ad/AdFullWidth'
import { AdGrid } from './Ad/AdGrid'

// TODO: Rename to AdStandardBlock
interface Props {
  block: BannerType
}

export const BannerBlock: React.FC<Props> = ({ block }) => {
  const variation = block?.theme || 'noFillPrimary'

  if (
    ['primary', 'secondary', 'noFillAlt'].includes(variation)
  ) {
    const themeMap = {
      'primary': 'primary',
      'secondary': 'secondary',
      'noFillAlt': 'transparent',
    }

    const reverse = variation === 'noFillAlt' === true || block.reverse
    const alignment = variation === 'noFillAlt' ? 'left' : 'center'

    return (
      <AdStandard
        title={block.bannerTitle}
        richTextDescription={block.description}
        image={block.bannerImage.url}
        ctaLabel={block.ctaLabel}
        href={block.href}
        reverse={reverse}
        variant={themeMap[variation] || 'primary'}
        bg="$transparent"
        alignment={alignment}
      />
    )
  }

  if (['fullWidthHover'].includes(variation)) {
    return (
      <AdFullWidth
        image={block.bannerImage.url}
        ctaLabel={block.ctaLabel}
        href={block.href}
      />
    )
  }

  if (['sideBySide'].includes(variation)) {
    const items = [
      {
        title: block?.bannerTitle,
        richTextDescription: block?.description,
        image: block?.bannerImage?.url,
        ctaLabel: block?.ctaLabel,
        href: block?.href
      },
      {
        title: block?.secondaryTitle,
        richTextDescription: block?.secondaryDescription,
        image: block?.secondaryBannerImage?.url,
        ctaLabel: block?.secondaryCtaLabel,
        href: block?.secondaryHref
      },
    ]

    return <AdGrid items={items} />
  }

  if (['sideBySideNoText'].includes(variation)) {
    const items = [
      {
        image: block?.bannerImage?.url,
      },
      {
        image: block?.secondaryBannerImage?.url,
      },
    ]

    return <AdGrid items={items} />
  }

  // we should never end up here but just in case, return null
  return null
}
