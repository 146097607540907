import { Paragraph, styled, YStack, GetProps } from '@mythical/ui'
import {
  HomepageContentBlock,
  LandingContentBlock,
  MerchPageContentBlock,
  PageContentBlock,
  PostContentBlock,
} from 'app/blocks'
import { BannerBlock } from 'app/components/blocks/Banner'
import { FeaturedBlock } from 'app/components/blocks/Featured'
import Section from 'app/components/structure/Section'
import getEnv from 'app/lib/env-getter'
import { CollectionType } from 'app/queries/collections/collection-fragment'
import { mapExcludedPlatforms } from 'app/utils/mapExcludedPlatforms'
import React from 'react'
import { Platform } from 'react-native'

import { CollectableRedemptionFormBlock } from '../blocks/CollectableRedemptionForm'
import { ImageBlock } from '../blocks/Image'
import { RichTextBlock } from '../blocks/RichText'
import { VideoBlock } from '../blocks/Video'
import { RichTextProps } from '../primitive/RichText'
import SignupConfirmation from '../primitive/SignupConfirmation'
import { FAQsBlock } from './FAQs'
import { PromoCodeBlock } from './PromoCode'
import { SpotifyEmbedBlock } from './Spotify'
import { ContentCardProps } from 'app/components/content/ContentCard'
import { PollBlock } from './Poll'
import { HomepageFeaturedBlock } from './homepage/HomepageFeatured'
import { CategoryLinksBlock } from './homepage/CategoryLinks'
import { ContinueWatchingBlock } from './homepage/ContinueWatching'
import { PopularBlock } from './homepage/Popular'
import { NextUpBlock } from './homepage/NextUp'
import { LatestBlock } from './homepage/Latest'
import { ComingUpBlock } from './homepage/ComingUp'
import { AllSeriesBlock } from './homepage/AllSeries'
import { RecommendedBlock } from './homepage/Recommended'
import { ImageCarouselBlock } from '../blocks/ImageCarousel'
import { CallToAction } from './CallToAction'
import { QuizBlock } from './homepage/Quiz'
import { ProductGridBlock } from './ProductGrid'
import { FormBlock } from 'app/components/blocks/Form'
import { LandingHeroBlock } from './landing/LandingHero'
import { LandingCollectiblesBlock } from './landing/LandingCollectibles'
import { LandingSingleSeriesBlock } from './landing/LandingSingleSeries'
import { LandingAllSeriesBlock } from './landing/LandingAllSeries'
import { WrappedBlock } from './homepage/Wrapped'
import { RedeemCollectableBlock } from './RedeemCollectable'
import { ButtonBlock } from './Button'

interface Props {
  blocks: (
    | PostContentBlock
    | PageContentBlock
    | HomepageContentBlock
    | LandingContentBlock
    | MerchPageContentBlock
  )[]
  collectionId?: string
  collectionType?: CollectionType
  featuredPosts?: ContentCardProps[]
  isPage?: boolean
  textProps?: RichTextProps['textProps']
  slug?: string
}

const BlocksWrapper = styled(YStack, {
  name: 'BlocksWrapper',
  variants: {
    spaced: {
      true: {
        gap: '$3',
        $gtXs: {
          gap: '$4',
        },
      },
    },
  } as const,
})

type BlocksWrapperProps = GetProps<typeof BlocksWrapper>

export const RenderBlocks: React.FC<Props & BlocksWrapperProps> = ({
  blocks,
  collectionId,
  collectionType,
  featuredPosts = [],
  isPage,
  slug,
  textProps,
  ...props
}) => {
  const OptionalWrapper = isPage ? Section : React.Fragment
  // @ts-ignore not all blocks have excludedPlatforms
  const blocksFiltered = blocks.filter(
    (block) =>
      !mapExcludedPlatforms(block?.excludedPlatforms || {})?.includes(
        Platform.OS
      )
  )
  return (
    <BlocksWrapper {...props}>
      {blocksFiltered.map((block, i) => {
        switch (block.__typename) {
          case 'Video':
            return <VideoBlock block={block} key={block.id} isUnlocked />

          case 'RichText':
            return (
              <OptionalWrapper key={block.id} {...(isPage && { padded: true })}>
                <RichTextBlock
                  block={block}
                  textProps={{ selectable: true, ...textProps }}
                />
              </OptionalWrapper>
            )

          case 'ImageBlock':
          case 'ExternalImage':
            return <ImageBlock block={block} key={block.id} />

          case 'Banner':
            return <BannerBlock block={block} key={block.id} />

          case 'Featured':
            return (
              <FeaturedBlock key={block.id} featuredPosts={featuredPosts} />
            )

          case 'Faqs':
            return <FAQsBlock block={block} key={block.id} />

          case 'CollectableRedemptionForm':
            return (
              <CollectableRedemptionFormBlock block={block} key={block.id} />
            )

          case 'Spotify':
            return <SpotifyEmbedBlock block={block} key={block.id} />

          case 'PromoCode':
            return <PromoCodeBlock block={block} key={block.id} />

          case 'SignupConfirmation':
            return <SignupConfirmation slug={slug} key={block.id} />

          case 'Poll':
            return <PollBlock block={block} key={block.id} />

          case 'HomepageFeatured':
            return <HomepageFeaturedBlock block={block} key={block.id} />

          case 'CategoryLinks':
            return <CategoryLinksBlock block={block} key={block.id} />

          case 'ContinueWatching':
            return <ContinueWatchingBlock block={block} key={block.id} />

          case 'Popular':
            return <PopularBlock block={block} key={block.id} />

          case 'NextUp':
            return <NextUpBlock block={block} key={block.id} />

          case 'Latest':
            return <LatestBlock block={block} key={block.id} />

          case 'ComingUpHomepage':
            return <ComingUpBlock block={block} key={block.id} />

          case 'AllSeries':
            return <AllSeriesBlock block={block} key={block.id} />

          case 'Recommended':
            return <RecommendedBlock block={block} key={block.id} />

          case 'Quiz':
            return <QuizBlock block={block} key={block.id} />

          case 'PromoBanner':
            return null // display nothing here as the banner must be located in the MainLayout

          case 'CallToAction':
            return <CallToAction block={block} key={block.id} />

          case 'ImageCarousel':
            return <ImageCarouselBlock block={block} key={block.id} />

          case 'ProductGrid':
            return <ProductGridBlock block={block} key={block.id} />

          case 'FormBlock':
            return <FormBlock block={block} key={block.id} />

          case 'LandingHero':
            return <LandingHeroBlock block={block} key={block.id} />

          case 'LandingCollectibles':
            return <LandingCollectiblesBlock block={block} key={block.id} />

          case 'LandingSingleSeries':
            return <LandingSingleSeriesBlock block={block} key={block.id} />

          case 'LandingAllSeries':
            return <LandingAllSeriesBlock block={block} key={block.id} />

          case 'Wrapped':
            return <WrappedBlock block={block} key={block.id} />

          case 'RedeemCollectable':
            return <RedeemCollectableBlock block={block} key={block.id} />

          case 'Button':
            return <ButtonBlock block={block} key={block.id} />

          default:
            return getEnv() === 'development' ? (
              <Paragraph color="white" key={i}>
                {JSON.stringify(block, null, 2)}
              </Paragraph>
            ) : (
              <Paragraph key={i} color="white" fontWeight="900">
                An app update is required to view this content.
              </Paragraph>
            )
        }
      })}
    </BlocksWrapper>
  )
}
