import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Platform } from 'react-native'
import { Spinner, YStack } from '@mythical/ui'
import { ButtonLink } from 'app/components/primitive/Button'

import Section from 'app/components/structure/Section'
import { RedeemCollectable } from 'app/blocks/redeem-collectable'
import useMutation from 'app/hooks/use-mutation'
import { useStores } from 'app/store'
import {
  redeemCollectibleMutation,
  redeemCollectibleResult,
} from 'app/mutations/redemption/redeem'
import {
  RedemptionForm,
  RedemptionFormValues,
} from 'app/components/redemption/RedemptionForm'
import { SizableText } from '@mythical/ui'
import RichText from '../primitive/RichText'
import { useDidFinishSSR } from '@mythical/ui'
import { ConfirmAddressModal } from '../redemption/ConfirmAddressModal'

const redeemMutation = redeemCollectibleMutation()

const isMobileWeb =
  Platform.OS === 'web' &&
  typeof navigator !== 'undefined' &&
  /iPhone|iPad|Android/i.test(navigator.userAgent || '')

interface Props {
  block: RedeemCollectable
}

export const RedeemCollectableBlock: React.FC<Props> = observer(({ block }) => {
  const { userStore } = useStores()
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [form, setForm] = useState<RedemptionFormValues>()

  const hasMounted = useDidFinishSSR()

  const redeemCollectibleMutation = useMutation(
    redeemMutation,
    redeemCollectibleResult
  )

  const handleFormSubmit = async (values: RedemptionFormValues) => {
    if (!userStore?.user) {
      return
    }

    setForm(values)
    setModalOpen(true)
  }

  const handleConfirm = async () => {
    if (!userStore?.user || !form) {
      return
    }

    setModalOpen(false)

    const result = await redeemCollectibleMutation.makeRequest({
      blockId: block.id,
      itemId: block.type === 'single' ? block.stockItem.id : form.stockItemId,
      ...form,
    })

    if (result?.success) {
      setSucceeded(true)
    } else {
      setError(result?.reason || 'An error occurred')
    }
  }

  if (!hasMounted || !userStore) {
    return (
      <Section ai="center" jc="center" bg="$secondary10">
        <Spinner size="large" />
      </Section>
    )
  }

  if (block.isExpired && block.expiredText) {
    return (
      <Section ai="flex-start" jc="flex-start" bg="$secondary10">
        <YStack>
          <RichText content={block.expiredText} />
        </YStack>
      </Section>
    )
  }

  if (block.hasRedeemed && block.redeemedText) {
    return (
      <Section ai="flex-start" jc="flex-start" bg="$secondary10">
        <YStack>
          <RichText content={block.redeemedText} />
        </YStack>
      </Section>
    )
  }

  if (!block.isEligible || !userStore.user) {
    return (
      <Section ai="flex-start" jc="flex-start" bg="$secondary10">
        <YStack>
          {block.ineligibleText && <RichText content={block.ineligibleText} />}
        </YStack>
      </Section>
    )
  }

  if (succeeded && block.successText) {
    return (
      <Section ai="flex-start" jc="flex-start" bg="$secondary10">
        <YStack>
          <RichText content={block.successText} />
        </YStack>
      </Section>
    )
  }

  return (
    <Section ai="flex-start" jc="flex-start" bg="$secondary10">
      {block.isEligible && block.introText && (
        <YStack>
          <RichText content={block.introText} />
        </YStack>
      )}
      {isMobileWeb && (
        <YStack marginTop="$2" marginBottom="$4" alignSelf="center">
          <ButtonLink
            href={`mythical-society://${window.location.pathname}${window.location.search}${window.location.hash}`}
          >Open in the app</ButtonLink>
        </YStack>
      )}
      <RedemptionForm
        onSubmit={handleFormSubmit}
        isSubmitting={redeemCollectibleMutation.loading}
        defaultValues={{
          name: userStore.user.fullName,
          address1: userStore.user.address?.street,
          city: userStore.user.address?.city,
          provinceCode: userStore.user.address?.state,
          zip: userStore.user.address?.postalCode,
          countryCode: userStore.user.address?.country,
          phone: userStore.user.phoneNumber,
        }}
        redeemButtonText={block.redeemButtonText}
        hasSucceeded={succeeded}
        stockItems={block.stockItems
          .filter((item) => item.inStock)
          .map((item) => ({
            id: item.stockItem.id,
            name: item.label || item.stockItem.name,
          }))}
        stockItemId={block.type === 'single' ? block.stockItem.id : ''}
      />
      {form && (
        <ConfirmAddressModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          form={form}
          onConfirm={handleConfirm}
        />
      )}
      {error && (
        <YStack>
          <SizableText textAlign="center" color="$red6">
            {error}
          </SizableText>
        </YStack>
      )}
    </Section>
  )
})
